<template>
    <v-dialog max-width="800px" v-model="show">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>Enquiry Status</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-form ref="mainForm">
                <v-row no-gutters>
                    <v-col cols="12" class="pa-3">
                        <v-select
                            v-model = "form.status"
                            :items="enquiryStatusList"
                            item-text="description"
                            item-value="code"
                            label="Status"
                            standard
                            outlined
                            v-if="show" autofocus
                        ></v-select>
                        <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="form.rcv_date"
                                    label="Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.rcv_date" @change="dpicker.menu1 = false"></v-date-picker>
                        </v-menu>
                        <v-text-field 
                            label="To" 
                            outlined
                            v-model="form.cces_to"
                        ></v-text-field>
                        <v-text-field 
                            label="Cell Phone No." 
                            outlined
                            v-model="form.cces_cell_phone"
                        ></v-text-field>
                        <v-text-field 
                            label="Email" 
                            outlined
                            v-model="form.cces_email"
                        ></v-text-field>
                        <v-text-field 
                            label="Subject" 
                            outlined
                            v-model="form.cces_subject"
                        ></v-text-field>                            
                        <v-textarea 
                            label="Description" 
                            outlined
                            v-model="form.cces_description"  
                            :rules="[rules.required]"
                        ></v-textarea>

                            
                   </v-col>
                </v-row>
                </v-form>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Save
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApCallcenterService from "@/services/ApCallcenterService"
import ApParameterService from "@/services/ApParameterService"
import moment from 'moment'
export default {
    name: "DlgEnquiryStatus",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
      
    },      
    data() {
    return {
        
      message: "",
      form:{
            login_id:"",
            cc_enquiry_id:0,
            status:"",
            rcv_date:"",
            rcv_time:"",
            cces_to:"",
            cces_cell_phone:"",
            cces_email:"",
            cces_subject:"",
            cces_description:"",
            prev_status:"",
            
          },
        dataList:[],
        enquiryStatusList:[],
        dpicker:{menu1: false},
        respCode:0,
        rules: {
            required: value => !!value || 'Required.',
            email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
            integer: value => /^[0-9]+$/.test(value) || 'Number only',
            decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
            pinNumber: value => value.length == 6 || 'require 6 digits number',
            searchWord: value => value.length >= 3 || 'require 3 or more letters',
        },
        errorFlag:0,
        
    };
  },
  async created(){
    

  },
  mounted(){
        this.form.rcv_date = moment(String(new Date())).format('YYYY-MM-DD');


  },
  methods: {
    
    dlgShow(enquiryStatusDetail){
        this.form = enquiryStatusDetail
        this.getParameter();
        // if(customer_address_id>0)
        //     this.viewDetails(customer_address_id);

    },
    dlgClose(){
        console.log('errorflag',this.errorFlag)
        if(!this.errorFlag){
            this.show = false;
        }
            
    },

    async save(){
        if(this.$refs.mainForm.validate()) {
            try {
                ApCallcenterService.insert_enquiry_status(this.form).then(() => {
                    //console.log(res);
                    this.$refs.dlgMsg.showDlgBox(true,'success','Update Enquiry Status','Success.');
                    this.errorFlag = 0;
                }).catch((e)=>{
                    console.log(e);
                    this.errorFlag = 1;
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Update Enquiry Status',e.response.data.error_message);
                })
                ;
                
            } catch(e) {
                this.errorFlag = 1;
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update Enquiry Status',e);
            }
        }
    },
        async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"update-enquirystatus",status:this.form.status}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.enquiryStatusList = res.data.enquiryStatusList;
                    console.log(res.data);                    
                }).catch((e)=>{
                    if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter','Unknown Error');
            }
        },



  }
};

</script>
